import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import axios from "../axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
const columns = [
  { field: "aisle", headerName: "Pasillo ", width: 130 },
  { field: "category", headerName: "Categoría ", width: 130 },
  { field: "subCategory", headerName: "Subcategoría", width: 130 },
  { field: "name", headerName: "Producto ", width: 130 },
  { field: "price", headerName: "Precio ", width: 130 },
  {
    field: "image",
    headerName: "Imagen",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          <img height={40} width={40} src={params.row.images} alt="" />
        </div>
      );
    },
  },
  {
    field: "description",
    headerName: "Descripción",
    width: 130,
  },
  {
    field: "size",
    headerName: "Tamaño",
    width: 130,
  },
];

export default function WarehouseProductDelete() {
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 12);
  const location = useLocation();
  const { products, id } = location.state;
  console.log(location.state, "datadata");
  const [loading, setLoading] = useState();
  const [productId, setProductId] = useState([]);
  console.log(productId, "productId");
  const navigation = useNavigate();

  const handleSubmit = () => {
    axios
      .post(`/api/v1/warehouse/deleteWarehouseProducts`, {
        productIds: productId,
        warehouseId: id
      })
      .then((res) => {
        console.log(res);
        // notify("Product added successfully");
        navigation("/WareHouseScreen")
      });
    console.log(productId);
  };
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <AdminNavbar />
      {loading ? (
        <>Loading</>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <div
            style={{
              minHeight: "60vh",
              width: "80%",
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <DataGrid
              rows={products}
              getRowId={(row) => row.pId}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              onSelectionModelChange={(ids) => {
                // const selectedIDs = ids;
                // const selectedRowData = products.filter((row) => {
                //   if (selectedIDs.has(row._id.toString())) {
                //     // row.pId = uuid();
                //     return row._id;
                //   }
                // });
                // selectedRowData.pId = small_id
                setProductId(ids);
              }}
              checkboxSelection
            />
          </div>
        </div>
      )}
      <div
        onClick={handleSubmit}
        style={{
          fontSize: 16,
          fontWeight: "600",
          margin: 10,
          padding: 5,
          cursor: "pointer",
          width: 100,
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          background: "#4C9F6F",
          borderRadius: 5,
          marginLeft: 60,
          marginBottom: 20,
          color: "white",
        }}
      >
        Borrar Productos
      </div>
    </div>
  );
}
