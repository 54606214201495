import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useStateValue } from "../ContextApi/StateProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { data } from "./wharhousedata";
export default function WarehouseTable({ warehouse }) {
  console.log(warehouse, "warehouse");
  const navigation = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [{ warehouseCart }, dispatch] = useStateValue();
  console.log(warehouseCart, "my warehouseCart");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };

  return (
    <div style={{ width: "99%", marginLeft: 10, background: "#F6F9FC" }}>
      <TableContainer style={{ background: "#F6F9FC", width: "97%" }}>
        <Table style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
          <TableHead>
            <TableRow>
              <TableCell style={Styles.tableHeading}>{""}</TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Nombre del almacén"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Ubicación del almacén"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Socios Asignados al almacén"}
              </TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warehouse
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    onClick={() =>
                      navigation("/WarehouseDetailTable", {
                        state: {
                          detailData: row,
                        },
                      })
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{
                      background: "#fff",
                      margin: "15px 0",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell style={{ borderRadius: "10px 0 0 10px" }}>
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          background: "#eee",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <SportsSoccerIcon />
                      </div>
                    </TableCell>

                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row.warehouseName}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row?.warehouseLocation?.loca}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row?.vendors?.length}
                      </div>
                    </TableCell>
                    <TableCell style={{ borderRadius: "0 10px 10px 0" }}>
                      <div style={{ color: "#444" }}>
                        <ArrowForwardIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={warehouse.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
