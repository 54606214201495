import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";
import axios from "../axios";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import Box from "@mui/material/Box";

import { useStateValue } from "../ContextApi/StateProvider";
import { useLocation, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import MenuItem from "@mui/material/MenuItem";
import { dummyData } from "./dummy";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import Creatable from "react-select/creatable";
import { useEffect } from "react";
import Colors from "../Theme/Colors";
import { IconButton } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};
function EditProduct() {
  const navigation = useNavigate();
  const location = useLocation();
  const params = location.state.productData;
  console.log(location.state.productData, "locationlocation");
  const [category, setCategory] = useState();
  const [subCategory1, setSubCategory1] = useState(["select category"]);
  const [aisle, setAisle] = React.useState("");
  const [allAisle, setAllAisle] = useState();
  const [allCategory, setAllCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [warehouse, setWarehouse] = useState();
  const [wID, setWId] = useState([""]);
  const [defalutValue, setDefaultValue] = useState([""]);
  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(e.target.result);
        setStoreImage(e.target.result);
      };
      // let img = URL.createObjectURL(event.target.files[0])
      // console.log(img)
      // setStoreImage(img)
    }
  };
  // const handleUpdate = () => {
  //   axios.put(`/api/v1/vendorImgUpdate/${vendorData?._id}`, {
  //     image: img,
  //   });
  // };
  console.log(params, "product Params");

  useEffect(() => {
    async function getWarehouser() {
      const req = await axios.get("/api/v1/warehouses");
      setWarehouse(req.data.warehouse);
      // console.log(req.data.warehouse)
      req.data.warehouse.forEach((doc) => {
        // console.log("doc")
        if (params.wId[0] == doc._id) {
          setDefaultValue(doc);
          console.log(doc, "doc");
        }
      });
    }
    getWarehouser();
  }, []);
  const notify = (e) => toast(e);

  const handleCategory = (e) => {
    setAllCategory(e.target.value);
    console.log(e.target.value, "inside sub");
  };
  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };
  console.log(aisle, "aisle");
  console.log(allCategory, "allCategory");
  console.log(subCategory1, "subCategory");
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (values) => {
    console.log(params._id, "myidmyidmyidmyidmyid");
    values.wId = [];
    if (wID.length != 0 && wID[0] != "") {
      values.wId.push(wID);
    } else {
      values.wId.push(defalutValue?._id);
    }
    values.img = values.sampleImg === "" ? params.images : values.sampleImg;
    console.log(values, "values");
    axios
      .post(`/api/v1/product/updateProduct/${params._id}`, {
        wId: values.wId,
        name: values.productName,
        size: values.size,
        stock: Number(values.stock),
        price: values.price,
        images: !img ? values.img : img,
        description: values.productDescription,
        aisle: aisle?.label?.toUpperCase(),
        category: allCategory?.label?.toUpperCase(),
        subCategory: subCategory?.label?.toUpperCase(),
        created: created,
      })
      .then((res) => {
        console.log(res.data, "mayresponsedata");
        notify("Product edited successfully");
      })
      .catch((err) => {
        console.log(err);
      });
    // setOpen(true);
  };
  const [product, setProduct] = useState([]);
  console.log(product, "myfetchproduct");
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get("/api/v1/products");
      setProduct(req.data.product);
    }
    fetchData();
  }, []);
  const [created, setCreated] = React.useState(false);
  function handleAisleCreated(opt, meta) {
    console.log(opt, meta);

    setAisle(opt);
    if (meta.action == "create-option") {
      setCreated(true);
    } else {
      getCategory(opt.label);
    }
  }
  const getCategory = async (val) => {
    let al = [];
    console.log(val, "inside function ");
    axios.get(`/api/v1/category/${val.toUpperCase()}`).then((res) => {
      console.log(res);
      res?.data?.cat.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al, "cat");
      setCategory(al);
    });
  };

  const handleCategoryget = async (opt, meta) => {
    setAllCategory(opt);
    let al = [];
    if (meta.action != "create-option") {
      console.log(opt, "inside function ");
      axios
        .get(`/api/v1/subCategory/${opt.label.toUpperCase()}`)
        .then((res) => {
          console.log(res);
          res?.data?.subCat.forEach((doc) => {
            al.push({ label: doc.label, value: doc.value });
          });
          console.log(al, "cat");
          setSubCategory(al);
        });
    }
  };
  const getAisle = async () => {
    let al = [];
    console.log("aisle");
    axios.get("/api/v1/aisle").then((res) => {
      console.log(res.data);
      res?.data?.aisle.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al);
      setAllAisle(al);
    });
  };
  useEffect(() => {
    getAisle();
  }, []);
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <Grid>
      <div>
        <Toaster />
      </div>
      <AdminNavbar />
      <Formik
        initialValues={{
          productName: params.name,
          size: params.size,
          stock: params.stock,
          price: params.price,
          sampleImg: "",
          productDescription: params.description,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleChange }) => (
          <div style={{ marginTop: 100 }}>
            <div
              style={{
                fontSize: 16,
                fontWeight: "700",
                fontSize: 23,
                marginLeft: 5,
                color: "#4C9F6F",
              }}
            >
              Editar producto
            </div>
            <Form>
              <div
                style={{
                  padding: 20,
                  borderBottomWidth: 1,
                }}
              >
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productName"
                  name="productName"
                  // defaultValue="zipCode"
                  values={values.productName}
                  disabled={false}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Nombre del Producto "
                  // variant="filled"
                />

                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  disabled={false}
                  id="size"
                  name="size"
                  values={values.size}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Tamaño del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  disabled={false}
                  id="stock"
                  name="stock"
                  values={values.stock}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Stock del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productDescription"
                  name="productDescription"
                  // defaultValue="zipCode"
                  values={values.productDescription}
                  disabled={false}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Descripción del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="price"
                  name="price"
                  // defaultValue="zipCode"
                  disabled={false}
                  values={values.price}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Precio del Producto"
                  // variant="filled"
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 23,
                  }}
                >
                  {storeImage && (
                    <Avatar
                      src={storeImage}
                      style={{
                        display: "flex",
                        width: 100,
                        height: 100,

                        alignSelf: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        justifySelf: "center",
                      }}
                    />
                  )}

                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleChangeImage}
                    id="contained-button-file"
                  />
                  <label
                    style={{ alignSelf: "center" }}
                    htmlFor="contained-button-file"
                  >
                    <IconButton
                      style={{ backgroundColor: "lightgray" }}
                      component="span"
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </label>
                </div>
                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {allAisle && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Pasillo"}
                      options={allAisle}
                      onChange={(opt, meta) => handleAisleCreated(opt, meta)}
                    />
                  )}
                </FormControl>

                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {(category || created) && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Categoría "}
                      options={category}
                      onChange={(opt, meta) => handleCategoryget(opt, meta)}
                    />
                  )}
                </FormControl>
                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {(subCategory || created) && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Subcategoría"}
                      options={subCategory}
                      onChange={(opt, meta) => setSubCategory(opt)}
                    />
                  )}
                </FormControl>
                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#4C9F6F",
                    width: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 10,
                    color: "white",
                    borderWidth: 0,
                    marginTop: 30,
                  }}
                >
                  Editar producto
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Grid>
  );
}

export default EditProduct;
