import { Button, Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";
import axios from "../axios";
import Alert from "@mui/material/Alert";

// import csv
// import { parse } from "csv";
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CircularProgress from '@mui/material/CircularProgress';
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import { useStateValue } from "../ContextApi/StateProvider";
import { Fab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { dummyData } from "./dummy";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Creatable from "react-select/creatable";
import { useDropzone } from "react-dropzone";
import { parse } from "papaparse";
import ProductTableCsv from "../PtoductTableCsv";
const addressScheme = Yup.object().shape({
  // aisle: Yup.string().required(" Aisle is required"),
  // category: Yup.string().required(" Category is required"),
  // subCategory: Yup.string().required(" Subcategory is required"),
  productName: Yup.string().required(" Product name is required"),
  size: Yup.string().required(" Product size is required"),
  stock: Yup.string().required(" Product stock is required"),
  price: Yup.string().required(" Product price is required"),
  sampleImg: Yup.string().required("Product Img is required"),
  productDescription: Yup.string().required(" Product description is required"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
function UploadExcel() {
  const aquaticCreatures = [
    { label: "Shark", value: 1 },
    { label: "Dolphin", value: "Dolphin" },
    { label: "Whale", value: "Whale" },
    { label: "Octopus", value: "Octopus" },
    { label: "Crab", value: "Crab" },
    { label: "Lobster", value: "Lobster" },
  ];
  const navigation = useNavigate();
  const wData = useLocation();
  console.log(wData.state);

  const [category, setCategory] = useState();
  const [subCategory1, setSubCategory1] = useState(["select category"]);
  const [allAisle, setAllAisle] = useState();
  const [aisle, setAisle] = React.useState("");
  const [allCategory, setAllCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  console.log(file, "filefile");
  function handleChange5(e) {
    setFile(e.target.files[0]);
  }

  const [created, setCreated] = React.useState(false);
  function handleAisleCreated(opt, meta) {
    console.log(opt, meta);

    setAisle(opt);
    if (meta.action == "create-option") {
      setCreated(true);
    } else {
      getCategory(opt.label);
    }
  }
  const notify = (e) => toast(e);

  const handleCategory = (e) => { };
  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };
  console.log(aisle, "aisle");
  console.log(allCategory, "allCategory");
  console.log(subCategory, "subCategory");

  const getCategory = async (val) => {
    let al = [];
    console.log(val, "inside function ");
    axios.get(`/api/v1/category/${val.toUpperCase()}`).then((res) => {
      console.log(res);
      res?.data?.cat.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al, "cat");
      setCategory(al);
    });
  };

  const handleCategoryget = async (opt, meta) => {
    setAllCategory(opt);
    let al = [];
    if (meta.action !== "create-option") {
      console.log(opt, "inside function ");
      axios
        .get(`/api/v1/subCategory/${opt.label.toUpperCase()}`)
        .then((res) => {
          console.log(res);
          res?.data?.subCat.forEach((doc) => {
            al.push({ label: doc.label, value: doc.value });
          });
          console.log(al, "cat");
          setSubCategory(al);
        });
    }
  };
  const getAisle = async () => {
    let al = [];
    console.log("aisle");
    axios.get("/api/v1/aisle").then((res) => {
      console.log(res.data);
      res?.data?.aisle.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al);
      setAllAisle(al);
    });
  };
  useEffect(() => {
    getAisle();
  }, []);

  const handleSubmit = async (values) => {
    setDisabled(true)
    setOpen(true)
    console.log(loading)
    console.log("cwork");
    axios
      .post("/api/v1/product/addBulkProduct", { products: csvData })
      .then((res) => {
        console.log(res.data, "mayresponsedata");
        setOpen(true)
        setOpen(false)
        notify("Product uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(values);
  };
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

  const [csvData, setCsvData] = useState();
  const changeHandler = (event) => {
    parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvData(results.data);
        console.log(results.data);
      },
    });
  };
  return (
    <Grid>
      <Toaster />
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Box>
        </Modal>
      </div>
      <AdminNavbar />
      {!csvData && (
        <div
          style={{
            marginTop: 80,
            justifyContent: "center",
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
            alignContent: "center",
            alignItems: "cen",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "70%",
              border: "1px dotted black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="filePicker"
              style={{
                position: "absolute",
              }}
            >
              <span
                style={{
                  borderRadius: 10,
                  backgroundColor: "lightgray",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Elegir Archivo{" "}
              </span>
              <span style={{ fontSize: 12, marginLeft: 3 }}>
                Ningún archivo seleccionado
              </span>
            </label>
            <input
              id="filePicker"
              type="file"
              name="file"
              accept=".csv"
              onChange={changeHandler}
              style={{
                display: "block",
                margin: "10px auto",
                visibility: "hidden",
              }}
            />
          </div>
        </div>
      )}

      <Grid
        container
        md={12}
        xs
        lg={12}
        justifyContent={"center"}
        flexDirection={"column"}
      >

        <Grid item md={8} xs lg={8}>
          {csvData && <ProductTableCsv data={csvData} />}
        </Grid>
        <Grid item>
          {csvData && (
            <button
              disabled={disabled}
              onClick={handleSubmit}
              type="submit"
              style={{
                cursor: "pointer",
                backgroundColor: "#4C9F6F",
                width: 150,
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                borderRadius: 10,
                padding: 10,
                color: "white",
                borderWidth: 0,
                marginBottom: 50,
                justifyItems: "center",
                alignContent: "center",
                alignSelf: "flex-end",
              }}
            >
              Agregar Producto
            </button>
          )}
        </Grid>
      </Grid>


    </Grid>
  );
}

export default UploadExcel;
