export const initialState = {
  cart: [],
  warehouseCart: [],
  qty: 1,
  admin: {},
};
export const getCartTotal = (cart) =>
  cart
    ?.map((item) => Number(item.price.replace("$", "")))
    ?.reduce((pre, curr) => pre + curr, 0);
const reducer = (state, action) => {
  console.log(action, "myactionperform");
  switch (action.type) {
    case "ADD_NEW_PRODUCT": {
      const item = action.item;
      console.log(item, "myItem");

      return {
        ...state,
        cart: [...state.cart, item],
      };
    }
    case "ADD_NEW_WAREHOUSE": {
      const item = action.item;
      console.log(item, "myItem");

      return {
        ...state,
        warehouseCart: [...state.warehouseCart, item],
      };
    }
    case "ADMIN_ADDED": {
      const item = action.item;
      return {
        ...state,
        admin: { item },
      };
    }
    default:
      return state;
  }
};
export default reducer;

// case "increament":
//   return {
//     ...state,
//     cart: [(state.cart.price += state.cart.price)],
//   };
// case "decrement":
//   return { count: state.count - 1 };
// default:
//   return state;
// }
