import { Grid } from "@mui/material";
import React, { Component, useState } from "react";
import Chart from "react-apexcharts";

const AdminBarChart = ({ newOrders }) => {
  const [allUsers, setAllUsers] = useState([]);
  let lastDate = Date.now() - 40 * 86400000; // Where the 6 is the number of days
  let today = Date.now();
  // console.log(allUsers, "allUsers");
  // useEffect(() => {
  //   let data = [];
  //   let user = [];
  //   axios.get("/api/v1/getNewUsers").then((res) => {
  //     setAllUsers(res.data.us);
  //     data.push();
  //   });
  // }, []);
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },

    xaxis: {
      tickAmount: 5,
      type: "datetime",
      min: new Date(lastDate).getTime(), // Where the 6 is the number of days
      max: new Date(today).getTime(),
      tickPlacement: "on",
    },
  });
  const [series, setSetSeries] = useState([{ data: newOrders }]);
  // console.log(series, users)

  return (
    <Grid container md={12} xs={7}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        md={12}
        xs={12}
      >
        <Chart
          options={options}
          series={series}
          type="bar"
          width={400}
          height={400}
        />
      </Grid>
    </Grid>
  );
};

export default AdminBarChart;
