import React from "react";
import Colors from "../Theme/Colors";
import { Grid } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import AllInboxIcon from "@mui/icons-material/AllInbox";
function AdminMoneyStatus({
  text,
  balance,
  icon,
  mainContainer,
  childContainer,
  iconStyle,
  textStyle,
  balanceStyle,
}) {
  return (
    <Grid container xs={11} md={12} lg={12} style={mainContainer}>
      <Grid xs={11} md={10} item style={childContainer}>
        <div style={iconStyle}>{icon}</div>
        <div style={textStyle}>{text}</div>
        <div style={balanceStyle}>{balance}</div>
      </Grid>
    </Grid>
  );
}

export default AdminMoneyStatus;
