export const dummyData = [
  {
    id: 1,
    name: "Confirmando Pedido",
  },
  {
    id: 2,
    name: "Pedido Confirmado",
  },
  {
    id: 3,
    name: "Procesando Pedido",
  },
  {
    id: 4,
    name: "Pedido Entregado",
  },
  {
    id: 5,
    name: "Cancelada",
  },
];
export const dummyData2 = [
  {
    id: 1,
    name: "Confirmando Pedido",
  },
  {
    id: 2,
    name: "Procesando Pedido",
  },
  {
    id: 3,
    name: "Pedido Confirmado",
  },
  {
    id: 4,
    name: "Pedido Entregado",
  },
  {
    id: 5,
    name: "Cancelada",
  },
];
// export const dummyData = [
//   {
//     id: 1,
//     name: "Pending",
//   },
//   {
//     id: 2,
//     name: "Processing",
//   },
//   {
//     id: 4,
//     name: "Cancelled",
//   },
//   {
//     id: 3,
//     name: "Delivered",
//   },
// ];
