import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StateProvider } from "./ContextApi/StateProvider";
import reducer, { initialState } from "./ContextApi/reducer";
import { BrowserRouter } from "react-router-dom";
// const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(

  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>
  ,
  document.getElementById("root")
);
