import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddNewProduct from "../components/AddNewProduct";
import ProductSelectTable from "../components/ProductSelectTable";
import EditWarehouse from "../components/EditWarehouse";

import AddNewWarehouse from "../components/AddNewWarehouse";
import AllProducts from "../components/AllProducts";
import OrderDetail from "../components/OrderDetail";
import EditProduct from "../components/EditProduct";

import Login from "../components/SignIn/Index";
import WarehouseDetailTable from "../components/WarehouseDetailTable";
import WarehouseTable from "../components/WareHouseTable";
import AdminScreen from "../Screens/AdminScreen";
import OrderScreen from "../Screens/OrderScreen";
import VendorScreen from "../Screens/VendorScreen";
import WarehouseScreen from "../Screens/WareHouseScreen";
import VendorDetailTable from "../VendorComponents/VendorDetailTable";
import { useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import UploadExcel from "../components/UploadExcel";
import EditWarehouseProduct from "../components/EditWarehouseProduct";
import SettingScreen from "../Screens/SettingScreen";
import UsersScreen from "../Screens/UsersScreen";
import AllProductDelete from "../AdminComponents/AllProductDelete";
import WarehouseProductDelete from "../AdminComponents/WarehouseProductDelete";

export default function AllRoute() {
  // const [{ admin }, dispatch] = useStateValue();
  const [newAdmin, setNewAdmin] = useState();
  // console.log(admin, "adminadmin");
  console.log(newAdmin, "newAdminnewAdmin");
  const [admin, setAdmin] = React.useState({});
  onAuthStateChanged(auth, (currentUser) => {
    setAdmin(currentUser);
  });
  // console.log(admin, "admin")
  // cons
  // useEffect(() => {
  //   const getData = async () => {

  //     let storeAdmin = await localStorage.getItem("admin");
  //     console.log(...storeAdmin, "2", null);
  //     setNewAdmin(JSON.parse(storeAdmin));
  //     console.log("storeAdminStoreAdmin");
  //   };
  //   getData();
  // }, []);

  return (
    <Router>
      {!admin ? (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<AdminScreen />} />
          <Route path="/SettingScreen" element={<SettingScreen />} />

          <Route path="/EditProduct" element={<EditProduct />} />
          <Route
            path="/EditWarehouseProduct"
            element={<EditWarehouseProduct />}
          />
          <Route path="/AllProductDelete" element={<AllProductDelete />} />
          <Route
            path="/WarehouseProductDelete"
            element={<WarehouseProductDelete />}
          />
          <Route path="/ProductSelectTable" element={<ProductSelectTable />} />
          <Route path="/EditWarehouse" element={<EditWarehouse />} />
          <Route path="/AllProducts" element={<AllProducts />} />
          <Route path="/AddNewProduct" element={<AddNewProduct />} />
          <Route path="/uploadExcel" element={<UploadExcel />} />
          <Route path="/VendorScreen" element={<VendorScreen />} />
          <Route path="/VendorDetailTable" element={<VendorDetailTable />} />
          <Route path="/Users" element={<UsersScreen />} />
          <Route path="/WareHouseScreen" element={<WarehouseScreen />} />
          <Route path="/WareHouseTable" element={<WarehouseTable />} />
          <Route path="/AddNewWarehouse" element={<AddNewWarehouse />} />
          <Route path="/OrderScreen" element={<OrderScreen />} />
          <Route path="/OrderDetail" element={<OrderDetail />} />
          <Route
            path="/WarehouseDetailTable"
            element={<WarehouseDetailTable />}
          />
        </Routes>
      )}
    </Router>
  );
}
