import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useStateValue } from "../ContextApi/StateProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import axios from "../axios";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
export default function WarehouseDetailTable() {
  const location = useLocation();
  const navigation = useNavigate();
  const warehouseData = location.state.detailData;
  console.log(warehouseData, "Location");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [{ cart }] = useStateValue();
  const [updateWarehouse, setUpdateWarehouse] = useState();
  console.log(updateWarehouse, "updateWarehouse");
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const req = await axios.get("/api/v1/warehouses");
      console.log(req.data, "warehouse products");
      setUpdateWarehouse(req.data.warehouse);
      setLoading(false);
    }
    fetchData();
  }, []);
  console.log(cart, "my cart");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    async function fetchData() {
      let da = [];
      setLoading(true);
      // warehouseData.wareHouseProduct.forEach(async (doc) => {
      //   console.log(doc,"doc")
      //   console.log(doc)
      //   const req = await axios.get(`/api/v1/getSomeProduct/${doc}`);
      //   console.log(req)
      //   da.push(req.data.product)
      // })
      await axios
        .post("/api/v1/getWarehouseProducts", { id: warehouseData._id })
        .then((res) => {
          console.log(res.data.product, "warehouse products");
          setAllProduct(res.data.product);
        });

      // const req = await axios.get(`/api/v1/getSomeProduct/${warehouseData.wareHouseProduct[0]}`);
      // console.log(req, "product")
      // setAllProduct(da);
      setLoading(false);
    }
    fetchData();
  }, [warehouseData]);

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };
  const [allProduct, setAllProduct] = useState();
  const [loading, setLoading] = useState();
  const getProduct = warehouseData?.wareHouseProduct;
  console.log(getProduct);
  const newId = warehouseData?._id;
  console.log(allProduct, "myfetchProduct");
  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true);
  //     axios.post(`/api/v1/allWarehouseProduct`,{
  //       id: warehouseData?.wareHouseProduct
  //     }).then((res) => {
  //       console.log(res.data.products,"myrespon")

  //       setAllProduct(res.data.products)
  //     }
  //   )
  //     setLoading(false);
  //   }
  //   fetchData();
  // }, [getProduct]);
  console.log(allProduct, "allProduct");
  const [vendor, setVendor] = useState([]);
  console.log(vendor, "myfetchvendor");
  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true);
  //     const req = await axios.get("/api/v1/vendor");
  //     setVendor(req.data.vendor);
  //     setLoading(false);
  //   }
  //   fetchData();
  // }, []);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const req = await axios.post("/api/v1/vendor/getWarehouseVendor", {
        id: warehouseData.vendors,
      });
      setVendor(req.data.vendor);
      setLoading(false);
    }
    fetchData();
  }, [warehouseData]);
  const handleRemove = async () => {
    console.log(warehouseData, "Params");
    await axios
      .delete(`/api/v1/warehouseProductDelete/${newId}`)
      .then((res) => {
        console.log(res.data, "my respoce");
      });
  };
  return (
    <div style={{ width: "100%", background: "#F6F9FC", minHeight: "96.5vh" }}>
      <div style={{ paddingBottom: 100 }}>
        <AdminNavbar />
      </div>

      <Grid
        item
        md={5}
        xs={12}
        style={{
          backgroundColor: "#A9A9A9",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: 10,
          margin: 20,
          padding: 15,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", padding: 4 }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "600",
              color: "black",
              width: 200,
            }}
          >
            Nombre del almacén:
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 3,
              fontWeight: "600",
              color: "white",
              width: 200,
            }}
          >
            {warehouseData.warehouseName}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: 4 }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "600",
              color: "black",
              width: 200,
            }}
          >
            Ubicación del almacén :
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 3,
              fontWeight: "600",
              color: "white",
              width: 200,
            }}
          >
            {warehouseData?.warehouseLocation?.loca}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: 4 }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "600",
              color: "black",
              width: 200,
            }}
          >
            Socios Asignados al almacén :
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 3,
              fontWeight: "600",
              color: "white",
              width: 200,
            }}
          >
            {warehouseData?.vendors.length}
          </div>
        </div>
      </Grid>
      <Grid
        container
        style={{ display: "flex", justifyContent: "flex-end" }}
        md={11}
        xs={11}
      >
        <Grid
          item
          md={2}
          xs={5}
          onClick={() => {
            navigation("/WarehouseProductDelete", {
              state: { products: allProduct, id: warehouseData._id },
            });
          }}
          style={{
            fontSize: 15,
            fontWeight: 700,
            color: "white",
            backgroundColor: "#4C9F6F",
            borderRadius: 10,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: "pointer",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            marginRight: 2,
          }}
        >
          Borrar Productos
        </Grid>
        <Grid
          item
          md={2}
          xs={5}
          onClick={() => {
            navigation("/EditWarehouse", {
              state: warehouseData,
            });
          }}
          style={{
            fontSize: 15,
            fontWeight: 700,
            color: "white",
            backgroundColor: "#4C9F6F",
            borderRadius: 10,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: "pointer",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            marginRight: 2,
          }}
        >
          Editar almacén
        </Grid>
        <Grid
          item
          md={2}
          xs={5}
          onClick={handleRemove}
          style={{
            fontSize: 15,
            fontWeight: 700,
            color: "white",
            backgroundColor: "#4C9F6F",
            borderRadius: 10,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: "pointer",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            marginRight: 2,
          }}
        >
          Eliminar almacén
        </Grid>
        <Grid
          item
          md={3}
          xs={5}
          onClick={() =>
            navigation("/ProductSelectTable", {
              state: warehouseData,
            })
          }
          style={{
            fontSize: 15,
            fontWeight: 700,
            color: "white",
            backgroundColor: "#4C9F6F",
            borderRadius: 10,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: "pointer",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          Asignar productos all almacén
        </Grid>
      </Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "10vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "90%" }}>
            <TableContainer style={{ padding: "15px" }}>
              <Table
                style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={Styles.tableHeading}>
                      {"Pasillo "}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Categoría "}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Subcategoría"}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Producto "}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Precio "}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Imagen"}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Descripción"}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Tamaño"}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>{"Stock"}</TableCell>
                  </TableRow>
                </TableHead>
                {allProduct && (
                  <TableBody>
                    {allProduct
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => {
                        return (
                          <TableRow
                            onClick={() =>
                              navigation("/EditWarehouseProduct", {
                                state: {
                                  productData: row,
                                  warehouseData,
                                },
                              })
                            }
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            style={{
                              background: "#fff",
                              margin: "15px 0",
                              cursor: "pointer",
                            }}
                          >
                            <TableCell
                              style={{ borderRadius: "10px 0 0 10px" }}
                            >
                              <div style={{ color: "#444" }}>{row.aisle}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>
                                {row.category}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>
                                {row.subCategory}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.name}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444", fontWeight: 600 }}>
                                {row.price}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div>
                                <img
                                  style={{ width: 30, height: 30 }}
                                  src={row.images}
                                />
                              </div>
                            </TableCell>
                            <TableCell style={{ color: "#444" }}>
                              {row.description}
                            </TableCell>
                            <TableCell style={{ color: "#444" }}>
                              {row.size}
                            </TableCell>

                            <TableCell style={{ color: "#444" }}>
                              {row.stock}
                            </TableCell>

                            <TableCell
                              style={{ borderRadius: "0 10px 10px 0" }}
                            >
                              <div style={{ color: "#444" }}>
                                <ArrowForwardIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={allProduct?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div style={{ width: "90%" }}>
            <TableContainer style={{ background: "#F6F9FC", padding: "15px" }}>
              <Table
                style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={Styles.tableHeading}>{""}</TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Nombre de la Tienda "}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Nombre de la Tienda"}
                    </TableCell>
                    <TableCell style={Styles.tableHeading}>
                      {"Estado del Socio"}
                    </TableCell>

                    <TableCell>{""}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendor
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ background: "#fff", margin: "15px 0" }}
                        >
                          <TableCell style={{ borderRadius: "10px 0 0 10px" }}>
                            <div>
                              <img
                                style={{ width: 30, height: 30 }}
                                src={row.image}
                              />
                            </div>
                          </TableCell>

                          <TableCell>
                            <div style={{ color: "#444", fontWeight: 600 }}>
                              {row.firstName}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444", fontWeight: 600 }}>
                              {row.storeName}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444", fontWeight: 600 }}>
                              {row.isActive === true ? (
                                <>Activo</>
                              ) : (
                                <>Inactivo</>
                              )}
                            </div>
                          </TableCell>
                          <TableCell style={{ borderRadius: "0 10px 10px 0" }}>
                            <div style={{ color: "#444" }}>
                              <ArrowForwardIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={vendor.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
