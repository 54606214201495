import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { data2 } from "./mockProductData";
import { useStateValue } from "../ContextApi/StateProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function AllProductTable({ data }) {
  console.log(data);
  const navigation = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [{ cart }, dispatch] = useStateValue();
  console.log(cart, "my cart");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };

  return (
    <div style={{ width: "95%", marginLeft: 10, background: "#F6F9FC" }}>
      <TableContainer style={{ background: "#F6F9FC", width: "100%" }}>
        <Table style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
          <TableHead>
            <TableRow>
              <TableCell style={Styles.tableHeading}>{"Pasillo"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Categoría"}</TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Subcategoría"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>{"Producto"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Precio"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Imagen"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Descripción"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Tamaño"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Stock"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    onClick={() =>
                      navigation("/EditProduct", {
                        state: {
                          productData: row,
                        },
                      })
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{ background: "#fff", margin: "15px 0" }}
                  >
                    <TableCell>
                      <div style={{ color: "#444" }}>{row.aisle}</div>
                    </TableCell>

                    <TableCell>
                      <div style={{ color: "#444" }}>{row.category}</div>
                    </TableCell>
                    <TableCell style={{ color: "#444" }}>
                      {row.subCategory}
                    </TableCell>
                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell style={{ color: "#444" }}>{row.price}</TableCell>
                    <TableCell>
                      <div>
                        <img
                          style={{ width: 30, height: 30 }}
                          src={row.images}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ color: "#444" }}>
                      {row.description}
                    </TableCell>

                    <TableCell style={{ color: "#444" }}>{row.size}</TableCell>
                    <TableCell style={{ color: "#444" }}>{row.stock}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
