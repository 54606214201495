import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminMoneyStatus from "../AdminComponents/AdminMoneyStatus";
import AdminBarChart from "../AdminComponents/AdminBarChart";
import AdminPieChart from "../AdminComponents/AdminPieChart";
import "./input.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import toast, { Toaster } from "react-hot-toast";

import Colors from "../Theme/Colors";
import AdminOrderStatus from "../AdminComponents/AdminOrderStatus";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import axios from "../axios";
import AdminDeliveryTab from "../components/AdminDeliveryTab";
import AdminPaymentTab from "../components/AdminPaymentTab";
import Checkbox from "@mui/material/Checkbox";
import { ToggleSlider } from "react-toggle-slider";
import { Button } from "react-bootstrap";

const drawerWidth = 240;

function SettingScreen(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [orders, setOrders] = useState();
  const loading = location.state;
  const notify = (e) => toast(e);

  console.log(loading, "loadingloading");
  const [activeTab, setActiveTabs] = useState("Configuraciones");
  const [activeTabData, setActiveTabData] = useState({});
  const [activeTabText, setActiveTabsText] = useState(activeTabData);
  const [activePaymentData, setActivePaymentData] = useState({});
  const [activePayment, setActivePayment] = useState(activePaymentData);
  const [deliveryText, setDeliveryText] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState("");
  const [deliveryStandard, setDeliveryStandard] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [getFee, setGetFee] = useState();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleServiceFee = async () => {
    axios
      .post("/api/v1/admin/settingScreen", {
        serviceFee: Number(serviceFee),
      })
      .then((res) => {
        console.log(res.data);
        notify("Service Fee updated successfully");
      });
  };
  useEffect(() => {
    let id = "63592dc1a86d529610ff4b67";
    axios.get(`/api/v1/admin/serviceFee/${id}`).then((res) => {
      console.log(res.data);
      setGetFee(res.data.getFee);
    });
  }, []);

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const handleFee = (e) => {
    setServiceFee(e.target.value);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Toaster />

      <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {false ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid
            style={{ width: "100%", height: "90vh" }}
            item
            md={8}
            lg={10}
            sm={12}
            xs={12}
          >
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginTop: 100,
                    marginBottom: 20,
                  }}
                >
                  Setting
                </h2>
              </Grid>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <table style={{ width: "80%" }}>
                    <tr>
                      <tr></tr>

                      <td>Service fee</td>
                      <td>
                        <input
                          className="formInput"
                          defaultValue={getFee?.serviceFee}
                          onChange={handleFee}
                          style={{
                            borderWidth: 0,
                            backgroundColor: "white",
                            width: 50,
                            padding: 5,
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </div>

                <Button
                  onClick={() => handleServiceFee()}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#4C9F6F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 10,
                    color: "white",
                    borderWidth: 0,
                    marginTop: 10,
                    marginLeft: 40,
                    width: 130,
                  }}
                >
                  Guardar Cambios
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

SettingScreen.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SettingScreen;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
