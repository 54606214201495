import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { parseISO } from "date-fns";

export default function UsersTable({
  users,
  page,
  pageSize,
  count,
  onChangePageSize,
  onPageChange,
}) {
  const handleChangePage = React.useCallback(
    (_, newPage) => {
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      onChangePageSize(+event.target.value);
      onPageChange(0);
    },
    [onChangePageSize, onPageChange]
  );

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };

  return (
    <div style={{ width: "99%", marginLeft: 10, background: "#F6F9FC" }}>
      <TableContainer style={{ background: "#F6F9FC", width: "97%" }}>
        <Table style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
          <TableHead>
            <TableRow>
              <TableCell style={Styles.tableHeading}>{"Nombre"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Correo"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Teléfono"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Dirección"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Creado en"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={row.id}
                  style={{
                    background: "#fff",
                    margin: "15px 0",
                  }}
                >
                  <TableCell>
                    <div style={{ color: "#444", fontWeight: 600 }}>
                      {row.userName}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ color: "#444", fontWeight: 600 }}>
                      {row.email}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ color: "#444", fontWeight: 600 }}>
                      {row.phoneNumber}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        color: "#444",
                        fontWeight: 600,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      - {row.address?.address}
                      {"\n- "}
                      {row.address?.addressOptional}
                      {"\n- "}
                      {row.address?.businessName}
                      {"\n- "}
                      {row.address?.zipCode}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ color: "#444", fontWeight: 600 }}>
                      {parseISO(row.createdAt).toLocaleString()}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={count}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
