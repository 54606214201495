import React, { useState } from "react";
import { MdPerson } from "react-icons/md";

import { useNavigate } from "react-router-dom";

const AdminDashBoard = (props) => {
  return (
    <div>
      <HeaderButton
        text="Tablero"
        btnColor="black"
        textColor="white"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/shop.png")}
      />
      <HeaderButton
        text="Producto"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/deals.png")}
      />
      <HeaderButton
        text="Ordenar"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/retry.png")}
      />
      <HeaderButton
        text="Usuarios"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        icon={<MdPerson width={24} height={24} />}
      />
      <HeaderButton
        text="Socios"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/recipies.png")}
      />
      <HeaderButton
        text="Almacenes"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/deals.png")}
      />
      <HeaderButton
        text="Configuraciones"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/recipies.png")}
      />
    </div>
  );
};

export default AdminDashBoard;

const HeaderButton = (props) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const handleChange = (props) => {
    props.setActiveTabs(props.text);
    if (props.text === "Tablero") {
      setLoading(true);
      navigation("/", {
        state: { loading },
      });
      setLoading(false);
    } else if (props.text === "Producto") {
      setLoading(true);
      navigation("/AllProducts", {
        state: { loading },
      });
      setLoading(false);
    } else if (props.text === "Ordenar") {
      setLoading(true);
      navigation("/OrderScreen", {
        state: { loading },
      });
      setLoading(false);
    } else if (props.text === "Usuarios") {
      setLoading(true);
      navigation("/Users", {
        state: { loading },
      });
      setLoading(false);
    } else if (props.text === "Almacenes") {
      setLoading(true);
      navigation("/WareHouseScreen", {
        state: { loading },
      });
      setLoading(false);
    } else if (props.text === "Configuraciones") {
      setLoading(true);
      navigation("/SettingScreen");
      setLoading(false);
    } else {
      setLoading(true);
      navigation("/VendorScreen", {
        state: { loading },
      });
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.activeTab === props.text ? "#4C9F6F" : "white",
        marginLeft: 9,
        marginRight: 9,
        paddingLeft: 15,
        paddingTop: 9,
        paddingBottom: 9,
        borderRadius: 6,
        marginBottom: 2,
        width: "90%",
        cursor: "pointer",
      }}
      onClick={() => handleChange(props)}
    >
      <div style={{ marginRight: 5 }}>
        {props.icon ? (
          props.icon
        ) : (
          <img
            style={{
              width: 20,
              height: 20,
            }}
            src={props.img}
            alt={props.text}
          />
        )}
      </div>
      <div
        style={{
          color: props.activeTab === props.text ? "white" : "black",
          fontSize: 15,
          fontWeight: "900",
        }}
      >
        {props.text}
      </div>
    </div>
  );
};
