import { Button, Grid, Input } from "@mui/material";
import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";
import Autocomplete from "react-google-autocomplete";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import { useStateValue } from "../ContextApi/StateProvider";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import Location from "../AdminComponents/Location";
import SubmitListingMap from "../AdminComponents/SubmitListingMap";
import MapGoogleView from "../AdminComponents/Map";
import Map from "../AdminComponents/Map";
import toast, { Toaster } from "react-hot-toast";

// import GoogleMapLoader from "../AdminComponents/MapGoogleView";
import { useLocation } from "react-router-dom";
const addressScheme = Yup.object().shape({
  warehouseName: Yup.string().required("Su nombre de almacén is required"),
});

function AddNewWarehouse() {
  const navigation = useNavigate();
  const location = useLocation();
  const params = location.state;
  const notify = (e) => toast(e);

  console.log(params.warehouseName, "locationlocation");
  const [{ cart }, dispatch] = useStateValue();
  const [form, setForm] = useState([]);
  const [loc, setLock] = useState();
  const addNewProduct = (values) => {
    dispatch({
      type: "ADD_NEW_WAREHOUSE",
      item: {
        warehouseName: values.warehouseName,
        warehouseLocation: loc,
      },
    });
  };
  const [formStepper, setFormStepper] = useState(0);
  const handleSubmit = (values) => {
    setForm({ newProduct: { ...values } });
    // values.location = loc
    // console.log(values)

    axios
      .put(`api/v1/warehouseEdit/${params._id}`, {
        warehouseName: values.warehouseName,
        warehouseLocation: loc,
      })
      .then((res) => {
        console.log(res.data, "mayresponsedata");
        notify("Warehouse edited successfully");
      });
  };
  const handleTab = (e) => {
    console.log(e);
    setLock(e);
  };

  return (
    <Grid>
      <Toaster />

      <AdminNavbar />
      <Formik
        initialValues={{
          warehouseName: params.warehouseName,
        }}
        validationSchema={addressScheme}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Grid container md={12} xs={12} style={{ marginTop: 100 }}>
            <Grid
              item
              md={12}
              xs={6}
              style={{
                fontSize: 16,
                fontWeight: "700",
                fontSize: 23,
                marginLeft: 5,
                color: "#4C9F6F",
              }}
            >
              Editar almacén
            </Grid>
            <Grid style={{}} item md={12} xs={12}>
              <Form>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{
                    padding: 20,
                    borderBottomWidth: 1,
                  }}
                >
                  <Field
                    component={TextField}
                    type="text"
                    id="warehouseName"
                    name="warehouseName"
                    values={values.warehouseName}
                    // defaultValue="values"
                    onChange={handleChange}
                    style={{
                      borderWidth: 1,
                      color: "green",
                      borderColor: "black",
                      width: "100%",
                      marginBottom: 10,
                    }}
                    label="Entrar en Almacén"
                    // variant="filled"
                  />

                  <button
                    type="submit"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#4C9F6F",
                      width: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                      padding: 10,
                      color: "white",
                      borderWidth: 0,
                      marginTop: 20,
                    }}
                  >
                    Guardar Cambios
                  </button>
                </Grid>
              </Form>
            </Grid>

            <Grid item md={12} xs={12} style={{}}>
              <Location handleTab={(data) => handleTab(data)} />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
}

export default AddNewWarehouse;
