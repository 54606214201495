import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { useEffect, useState } from "react";
import markerIcon from "../assets/marker.png";
import NearMeIcon from "@mui/icons-material/NearMe";
import Autocomplete from "react-google-autocomplete";
import { CircularProgress, Grid } from "@mui/material";
// import { useSelector } from "react-redux";

const center = {
  lat: 31.5204,
  lng: 74.3587,
};
const MY_API_KEY = "AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0";
const CustomSubmitMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MY_API_KEY}&libraries=geometry,drawing,places&v=weekly&channel=2`,

    loadingElement: (
      <div style={{ height: "30vh", width: "100%", borderRadius: 10 }}>
        {" "}
        <CircularProgress sx={{ color: "#499b0f" }} size={50} />
      </div>
    ),
    containerElement: (
      <div style={{ height: "30vh", width: "100%", borderRadius: 10 }} />
    ),
    mapElement: (
      <div
        id={"submitListingMap"}
        style={{
          height: "30vh",
          width: "100%",
          borderRadius: 10,
          marginTop: 30,
        }}
      />
    ),
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <Grid container md={12} xs={12}>
    <Grid
      style={{
        display: "flex",
        marginBottom: 20,
        padding: 20,
        width: "200px",
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
      item
      md={12}
      xs={12}
    >
      <GoogleMap
        google={props.google}
        defaultZoom={12}
        defaultCenter={props.mapPosition}
      >
        {/* {/Marker/} */}
        <Marker
          icon={markerIcon}
          draggable={true}
          onDragEnd={props.onMarkerDragEnd}
          position={props.markerPosition}
        />
        {/* <Marker /> */}
        {/* For Auto complete Search Box */}
      </GoogleMap>
    </Grid>
  </Grid>
));
const SubmitListingMap = (props) => {
  // const country = 'es'
  console.log(props.mapView);
  const [markerData, setMarkerData] = useState([]);
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    if (props.markerPosition) {
      setMarkerData(props.markerPosition);
    }
    if (props.mapPosition) {
      setMapData(props.mapPosition);
    }
    // eslint-disable-next-line
  }, [props]);
  return (
    <>
      {!props.mapView && (
        <Grid item md={12} xs={12} padding={5}>
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",

              border: "1px solid black",
              borderRadius: 10,
              padding: 10,
              marginTop: 25,
              marginBlock: 20,
            }}
            apiKey={MY_API_KEY}
            placeholder="Add your address"
            options={{
              types: ["(regions)"],
              componentRestrictions: { country: props.country },
            }}
            onChange={(e) => {
              e.preventDefault();
            }}
            onPlaceSelected={props.onPlaceSelected}
            types={["(regions)"]}
          />
          <div
            style={{ width: "100%", backgroundColor: "lightgray", height: 1 }}
          />

        </Grid>
      )}
      {props.mapView && (
        <CustomSubmitMap
          mapPosition={mapData}
          markerPosition={markerData}
          onPlaceSelected={props.onPlaceSelected}
          onMarkerDragEnd={props.onMarkerDragEnd}
          country="pk"
          mapView={props.mapView}
        />
      )}
    </>
  );
};

export default SubmitListingMap;
