import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
export const data2 = [
  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "30",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "40",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "78",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "25",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },

  {
    itemImg: <SportsSoccerIcon />,
    productName: "Nike react phantom running kit phantom",
    stock: "1",
    regularprice: "$350.00",
    saleprice: "$350.00",
    nextImg: <ArrowForwardIcon />,
  },
];
