import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Routes, Route, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { dummyData, dummyData2 } from "./dummy";
import MenuItem from "@mui/material/MenuItem";
import axios from "../axios";

import { ArrowBackTwoTone, ArrowLeftTwoTone } from "@material-ui/icons";
import toast, { Toaster } from "react-hot-toast";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment/moment";
import io from "socket.io-client";
const styleImg = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 300,
  bgcolor: "background.paper",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const OrderDetail = () => {
  const location = useLocation();
  const params = location.state.productDetails;
  let date = new Date();
  console.log(params._id, "dattatatat");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [category, setCategory] = useState();
  const [status, setStatus] = React.useState("");
  const [order, setOrder] = useState();
  console.log(status, "letSee");
  const [updatedStatus, setUpdateStatus] = React.useState(status);
  const navigate = useNavigate();

  console.log(updatedStatus, "newStatus");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const notify = (e) => toast(e);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    const getStatus = () => {
      axios
        .get(`/api/v1/allOrdersDetail/${params._id}`)
        .then((res) => {
          console.log(res);
          setStatus(res.data.allOrdersDetail.status);
          setOrder(res.data.allOrdersDetail);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const socket = io(
      "https://orca-app-2hy8c.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    );
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      getStatus();
    });
    getStatus();
  }, []);

  const handleStatus = async (e) => {
    console.log(e, "orderDetails");
    setStatus(e.target.value);
    setUpdateStatus(e.target.value);
  };

  const handleChange = async () => {
    await axios
      .put(`/api/v1/allOrdersDetail/status/${params._id}`, {
        status: status,
      })
      .then((res) => {
        console.log(res.data, "response from order");
        notify("Status updated successfully");
      });
  };
  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleImg}>
          <img style={{ width: 270, height: 200 }} src={order?.pop} />
        </Box>
      </Modal>
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 40,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <h2>Detalles del pedido</h2>
        </Grid>
        <Grid>
          <ArrowBackTwoTone
            style={{
              height: 30,
              width: 30,
              color: "black",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            item
            onClick={() => navigate(-1)}
          />
        </Grid>
      </Grid>

      <Toaster />
      <Grid container>
        <Grid
          lg={10}
          md={12}
          sm={12}
          sx={12}
          style={{
            width: "100%",
            padding: 20,
          }}
        >
          {order && (
            <Grid container>
              <Grid style={{ padding: "15px" }} lg={6} md={6} sm={12} sx={12}>
                <Grid container style={styles.card}>
                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <h4 style={{ margin: 0, padding: "10px" }}>
                      Order summary
                    </h4>
                    <Grid container>
                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Ship by:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.hightLightTxt}>
                            {moment(
                              new Date(order.purchaseDate).addHours(
                                order?.deliveryTime?.deliveryTime
                              )
                            ).format("DD-MM-YYYY, HH:mm:ss A")}
                          </p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Fecha de compra:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.valueTxt}>
                            {moment(order.purchaseDate).format(
                              "DD-MM-YYYY, HH:mm:ss A"
                            )}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <Grid container>
                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Payment Type</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.hightLightTxt}>
                            {order?.paymentType?.type}
                          </p>
                        </Grid>
                      </Grid>
                      {/* 
                    <Grid container>
                      <Grid lg={6} md={6} sm={12} sx={12}>
                        <p style={styles.titleTxt}>Shipping service:</p>
                      </Grid>
                      <Grid lg={6} md={6} sm={12} sx={12}>
                        <p style={styles.hightLightTxt}>Expected</p>
                      </Grid>
                    </Grid> */}

                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Depósito:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>Basimart</p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Canal de ventas:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>basimart.com</p>
                        </Grid>
                      </Grid>
                      <Grid lg={12} md={12} sm={12} xs={12}>
                        {order.paymentType.type === "Pagar en efectivo" ? (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="Estado">Estado</InputLabel>
                            <Select
                              displayEmpty={false}
                              id="Estado"
                              value={status}
                              onChange={handleStatus}
                              label="Estado"
                              style={{ width: "100%" }}
                            >
                              {dummyData2.map((item) => (
                                <MenuItem
                                  style={{ width: "100%" }}
                                  onClick={() => setCategory(item.values)}
                                  value={item.name}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="Estado">Estado</InputLabel>
                            <Select
                              displayEmpty={false}
                              id="Estado"
                              value={status}
                              onChange={handleStatus}
                              label="Estado"
                              style={{ width: "100%" }}
                            >
                              {dummyData.map((item) => (
                                <MenuItem
                                  style={{ width: "100%" }}
                                  onClick={() => setCategory(item.values)}
                                  value={item.name}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    md={4}
                    xs={6}
                    onClick={() => handleChange()}
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      color: "white",
                      backgroundColor: "#4C9F6F",
                      padding: 10,
                      borderRadius: 10,
                      marginRight: 30,
                      cursor: "pointer",
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                      marginBottom: -10,
                      marginTop: 5,
                    }}
                  >
                    Guardar Cambios
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                style={{ padding: "15px", width: "100%" }}
                lg={6}
                md={6}
                sm={12}
                sx={12}
              >
                <Grid container style={styles.card}>
                  <Grid lg={12} md={12} sm={12} sx={12}>
                    <Grid container>
                      <Grid lg={12} md={12} sm={12} sx={12}>
                        <h4 style={{ margin: 0 }}>Dirección de Entrega:</h4>
                        <p style={styles.buyerName}>{params.shipTo}</p>
                        {/* <p style={styles.titleTxt}>{params.shipTo}</p> */}
                      </Grid>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Teléfono del Usuario</p>
                        <p style={styles.buyerName}>{params.cellPhone}</p>
                      </Grid>
                      {/* <Grid lg={12} md={12} sm={12} sx={12}>
                    </Grid> */}
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Nombre del Usuario</p>
                        <p style={styles.buyerName}>{params.buyerName}</p>
                      </Grid>

                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Código Postal</p>

                        <p style={styles.buyerName}>
                          {params?.deliveryAddress?.zipCode}
                        </p>
                      </Grid>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Otros Datos </p>

                        <p style={styles.buyerName}>
                          {params?.deliveryAddress?.addressOptional}
                        </p>
                      </Grid>
                      {order.leaveAtDoor && (
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          sx={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <p style={styles.titleTxt}>
                            Instrucciones al repartidor:
                          </p>
                          <p style={styles.buyerName}>
                            dejar el paquete en la puerta del cliente
                          </p>
                        </Grid>
                      )}
                      {order?.deliveryAddress?.instruction && (
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          sx={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <p style={styles.titleTxt}>Más instrucciones</p>
                          <p style={styles.buyerName}>
                            {order?.deliveryAddress?.instruction}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Order detail table */}

          {order && (
            <div>
              <div style={{ width: "95%", marginLeft: 10 }}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "10px",
                    letterSpacing: "1px",
                  }}
                >
                  Productos
                </h2>
                <TableContainer
                  style={{ background: "#F6F9FC", padding: "15px" }}
                >
                  <Table
                    style={{
                      borderSpacing: "0 15px",
                      borderCollapse: "separate",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={Styles.tableHeading}>
                          {"Imagen"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Nombre del producto"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Descripción"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Cantidad"}
                        </TableCell>

                        <TableCell style={Styles.tableHeading}>
                          {"Tamaño"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Precio unitario"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Precio total"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {order?.productDetails?.map((row) => {
                      return (
                        <TableBody>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            style={{
                              background: "#fff",
                              margin: "15px 0",
                              cursor: "pointer",
                            }}
                          >
                            <TableCell
                              style={{ borderRadius: "10px 0 0 10px" }}
                            >
                              <div>
                                <img
                                  style={{ width: 30, height: 30 }}
                                  src={row.images}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ color: "#444", width: "400px" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              style={{ color: "#444", width: "300px" }}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.qty}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.size}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>
                                {row.basePrice}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.price}</div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                </TableContainer>
                <div style={{ fontSize: 15, fontWeight: "600" }}>
                  Comprobante de Pago
                </div>
                <img
                  onClick={() => setOpen(true)}
                  style={{ cursor: "pointer", width: "20%", height: "20%" }}
                  src={order?.pop}
                />
              </div>
            </div>
          )}
        </Grid>

        {/* Right sidebar */}
      </Grid>
    </>
  );
};

export default OrderDetail;
const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: 15,
  },
  titleTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#888",
    padding: "10px",
    margin: 0,
  },
  valueTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#222",
    margin: 0,
    padding: "10px",
  },
  hightLightTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#AD6D49",
    padding: "10px",
    margin: 0,
  },
  buyerName: {
    color: "#307682",
    fontSize: 12,
    fontWeight: "bold",
    padding: "10px",
    margin: 0,
  },
};

const Styles = {
  tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
};
