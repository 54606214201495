import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import * as Yup from "yup";
import Colors from "../../Theme/Colors";
import styled from "styled-components";
import { useStateValue } from "../../ContextApi/StateProvider";
import { useState } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
const loginSchema = Yup.object().shape({
  email: Yup.string().required().label("email"),
  password: Yup.string()
    .required()
    .min(6, "At least 6 characters")
    .label("Password"),
});

export default function SignIn() {
  const navigation = useNavigate();
  const [{ admin }, dispatch] = useStateValue();
  const [loading, setLoading] = useState();

  const addAdminToStore = (loginUser) => {
    dispatch({
      type: "ADMIN_ADDED",
      item: loginUser,
    });
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    axios
      .post("/api/v1/admin/login", {
        email: values.email,
        password: values.password,
      })
      .then(async (res) => {
        if (res.data.success) {
          localStorage.setItem("admin", JSON.stringify(res.data.admin));
          signInWithEmailAndPassword(auth, values.email, values.password)
            .then((res) => {
              console.log(res);
              addAdminToStore(res);
            })
            .catch((err) => {
              console.log(err.response.data.success);
            });
        }
      });

    console.log("after context");
    setLoading(false);
    navigation("/", {
      state: {
        loading,
      },
    });
  };
  return (
    <>
      <Container style={{ width: "100%" }}>
        <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
          <Typography component="h3" variant="h6" style={Styles.LogInText}>
            Iniciar sesión
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            <Form>
              <Field
                component={TextField}
                margin="normal"
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="email"
                disabled={false}
              />
              <Field
                component={TextField}
                margin="normal"
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={false}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={Styles.SignInButton}
              >
                <div>Iniciar sesión</div>
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Términos y condiciones
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Container>
    </>
  );
}

const Styles = {
  OrStyling: {
    backgroundColor: Colors.seeGreen,
    borderRadius: 20,
    color: "white",
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  LogInButton: {
    backgroundColor: Colors.seeGreen,
    padding: 15,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
  },

  SignInButton: {
    backgroundColor: "#4C9F6F",
    borderRadius: 7,
    width: "100%",
    fontSize: 12,
    textTransform: "none",
  },
  SignUpButton: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 7,
    width: "50%",
    fontSize: 12,
  },
  LogInText: { width: "100%", fontSize: 25, fontWeight: "600" },
  NoAccountText: { color: Colors.lightBlack, paddingTop: 10 },
};
const ForgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10;
  padding-bottom: 15;
`;
const ResetButton = styled.div`
  color: ${Colors.lightGreen};
  font-weight: 700;
`;
const SignUpContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
