export default {
  darkBlue: "#212240",
  skyBlue: "#377ef9",
  lightGreen: "#4C9F6F",
  white: "#FFFFFF",
  lightBlack: "#343538",
  lightgray: "#F6F7F8",
  seeGreen: "#EAF5EB",
  seaGreen: "rgb(69, 206, 69)",
  seaBlue: "#4483F8",
  seaGreen: "lightGreen", //navbar
  seaGray: "#4894A3",
  reaBtnClr: '#52AE11'
};
