import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminMoneyStatus from "../AdminComponents/AdminMoneyStatus";
import AdminBarChart from "../AdminComponents/AdminBarChart";
import AdminPieChart from "../AdminComponents/AdminPieChart";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Colors from "../Theme/Colors";
import AdminOrderStatus from "../AdminComponents/AdminOrderStatus";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../axios";
const drawerWidth = 240;

function AdminScreen(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [orders, setOrders] = useState();
  const [orderAmount, setOrderAmount] = useState();
  const [users, setUsers] = useState();
  const [newOrders, setNewOrders] = useState();
  const loading = location.state;
  console.log(loading, "loadingloading");
  const [activeTab, setActiveTabs] = useState("Tablero");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getAllOrders = async () => {
    axios.get("/api/v1/orders/getAllOrders").then((res) => {
      console.log(res.data, "all orders");
      setOrders(res.data);
    });
    axios.get("/api/v1/order/orderAmount").then((res) => {
      console.log(res.data);
      setOrderAmount(res.data);
    });
    axios.get("/api/v1/getNewUsers").then((res) => {
      console.log(res.data, "user hain");
      setUsers(res.data.users);
    });
    axios.get("/api/v1/getAllMonthOrder").then((res) => {
      console.log(res.data, "user hain");
      setNewOrders(res.data.users);
    });
  };
  useEffect(() => {
    getAllOrders();
  }, []);

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {false ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid style={{ width: "100%" }} item md={8} lg={10} sm={12} xs={12}>
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginTop: 100,
                    marginBottom: 20,
                  }}
                >
                  Tablero
                </h2>
              </Grid>

              <Grid container sx={12} md={12} item style={{}}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={4}
                >
                  {orderAmount && (
                    <AdminMoneyStatus
                      icon={<WalletIcon />}
                      text="Pedidos de hoy"
                      balance={`₡${orderAmount.today[0]?.sum_val
                          ? orderAmount.today[0]?.sum_val?.toFixed(1)
                          : 0
                        }`}
                      mainContainer={balanceStyles.mainContainer}
                      childContainer={balanceStyles.todayOrders}
                      iconStyle={balanceStyles.iconStyle}
                      textStyle={balanceStyles.textStyle}
                      balanceStyle={balanceStyles.balanceStyle}
                    />
                  )}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={4}
                >
                  {orderAmount && (
                    <AdminMoneyStatus
                      icon={<ShoppingCartIcon />}
                      text="Pedidos de este mes"
                      balance={`₡${orderAmount?.thisMonth[0]?.sum_val
                          ? orderAmount?.thisMonth[0]?.sum_val?.toFixed(1)
                          : 0
                        }`}
                      mainContainer={balanceStyles.mainContainer}
                      childContainer={balanceStyles.MonthlyOrders}
                      iconStyle={balanceStyles.iconStyle}
                      textStyle={balanceStyles.textStyle}
                      balanceStyle={balanceStyles.balanceStyle}
                    />
                  )}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={4}
                >
                  {orderAmount && (
                    <AdminMoneyStatus
                      icon={<AllInboxIcon />}
                      text="Total de Pedidos"
                      balance={`₡${orderAmount?.allOrders[0]?.sum_val
                          ? orderAmount?.allOrders[0]?.sum_val?.toFixed(1)
                          : 0
                        }`}
                      mainContainer={balanceStyles.mainContainer}
                      childContainer={balanceStyles.totalOrders}
                      iconStyle={balanceStyles.iconStyle}
                      textStyle={balanceStyles.textStyle}
                      balanceStyle={balanceStyles.balanceStyle}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                sx={12}
                md={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={2.9}
                >
                  {orders?.allOrders && (
                    <AdminOrderStatus
                      icon={<WalletIcon />}
                      text="Total de Pedidos"
                      balance={orders?.allOrders?.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.orderChildContainer}
                      iconStyle={orderStyles.iconStyleTotal}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={2.9}
                >
                  {orders?.pendingOrder && (
                    <AdminOrderStatus
                      icon={<WalletIcon />}
                      text="Confirmando Pedido"
                      balance={orders?.pendingOrder.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.orderChildContainer}
                      iconStyle={orderStyles.iconStylePending}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={2.9}
                >
                  {orders?.processingOrder && (
                    <AdminOrderStatus
                      icon={<ShoppingCartIcon />}
                      text="Procesando Pedido"
                      balance={orders.processingOrder.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.orderChildContainer}
                      iconStyle={orderStyles.iconStyleProcessing}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={2.9}
                >
                  {orders?.deliveredOrder && (
                    <AdminOrderStatus
                      icon={<AllInboxIcon />}
                      text="pedido entregado"
                      balance={orders.deliveredOrder.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.orderChildContainer}
                      iconStyle={orderStyles.iconStyleDelivered}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                sx={12}
                md={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={12}
                >
                  {
                    <iframe
                      // width="100%"
                      // height="100%"
                      scrolling="no"
                      style={{
                        // background: "#F1F5F4",
                        border: "none",
                        borderRadius: "2px",
                        // boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                        height: "300px",
                        width: "200%",
                        // width: "200%",
                        overflow: "hidden",
                      }}
                      src="https://charts.mongodb.com/charts-basimart-rdbtm/embed/dashboards?id=63667884-649b-49ad-83cf-61f43b18322e&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"
                    ></iframe>
                  }
                </Grid>
                {/* <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  md={6}
                >
                  {
                    // <iframe
                    //   scrolling="no"
                    //   style={{
                    //     // background: "#F1F5F4",
                    //     border: "none",
                    //     borderRadius: "2px",
                    //     // boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                    //     height: "300px",
                    //     // width: "400px",
                    //     // width: "200%",
                    //     overflow: "hidden",
                    //   }}
                    //   src="https://charts.mongodb.com/charts-ecommerce-yonnp/embed/dashboards?id=6359593e-96b6-41c4-8b74-f751124e376e&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"
                    // ></iframe>
                  }
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

AdminScreen.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminScreen;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
