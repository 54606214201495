import AllRoute from "./navigation/AllRoutes";

function App() {
  return (
    <div style={{ backgroundColor: "#F6F9FC" }}>
      <AllRoute />
    </div>
  );
}

export default App;
